<script>
import ModalBigger from "./ModalBigger.vue";
import { AxiosAPI } from "@/axios";
import Loading from '../Modals/Loading.vue';
import { useMainStore } from "../../../stores/index";
import { useBusStore } from "../../../stores/index";
export default {
	components: {
		ModalBigger,
		Loading
	},

	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},

	data() {
		return {
			documento_name: 'Carregando...',
			documento_src: null,
			document_status_move: null,
			documento_statusid: null,
			documento_id: null,
			documento_src_proc: null,
			comissions_choice: [],
			comissions: [],
			secretario: null,
			document_id_chose: [],
			documents_in_secretario: [],
		};
	},

	methods: {
		openModal(event) {
			this.Comissions();

			this.documento_name = event.name;
			this.documento_src = event.attachment;
			this.document_status_move = event.document_status_movement_id;
			this.documento_statusid = event.document_status_id;
			this.documento_id = event.id;
			this.documento_src_proc = event.parecer_procurador_pdf;

			this.$refs.viewDocument.openModal();
		},

		openModal_secretario(event) {
			this.Comissions();

			this.secretario = true;
			this.documents_in_secretario = event;

			this.$refs.viewDocument.openModal();
		},

		CloseModalAnexo(){this.$refs.viewDocument.closeModal();},

		async send_Parecer_append(){
			this.$refs.Loading.openModal();
            
			if (this.comissions_choice == 0) {

				const formData = new FormData();

				formData.append("comission_id", 1);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				await AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});

			} else if (this.comissions_choice == 1) {
                

				const formData = new FormData();

				formData.append("comission_id", 2);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			} else if (this.comissions_choice == 2) {
                

				const formData = new FormData();

				formData.append("comission_id", 3);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			} else if (this.comissions_choice == 3) {
                

				const formData = new FormData();

				formData.append("comission_id", 4);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			} else if (this.comissions_choice == 4) {
                

				const formData = new FormData();

				formData.append("comission_id", 5);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			} else if (this.comissions_choice == 5) {
                

				const formData = new FormData();

				formData.append("comission_id", 6);
				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/documents-comission-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			} else if (this.comissions_choice == 6) {
                

				const formData = new FormData();

				formData.append("document_id", this.document_id_chose);
				formData.append("append_id", this.StoreAll.document_details.id);

				AxiosAPI.post("/parecer-append", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.CloseModalAnexo();
						this.Bus.AxexoSuccess = true;
					});
			}
		},

		ValidatInputFile() {
			const selectedFile = document.getElementById("formFile").files[0];
			this.header_error = '';
			this.body_error = '';
			if (selectedFile) {
				if (selectedFile.type !== "application/pdf") {
					this.header_error = 'Documento inválido';
					this.body_error = 'Apenas documentos PDF são permitidos para envio.';
					this.show_error();
					document.getElementById('formFile').value = '';
					console.log(document.getElementById("formFile").files[0]);
					return;
				}
			} else {
				console.log("Nenhum arquivo selecionado.");
			} 

		},

		Comissions() {
			if (this.StoreAll.host == 'dev') {
				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;


			} else if (this.StoreAll.host == 'teste') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'jc') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'bodo') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'pedroavelino') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'pedrapreta') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'campogrande') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'lajes') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'parazinho') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'jandaira') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "AGRICULTURA, OBRAS, TRANSPORTE E URBANISMO" },
					{ id: 3, name: "EDUCAÇÃO, CULTURA, ESPORTE, LAZER, MEIO AMBIENTE E TURISMO" },
					{ id: 4, name: "SAÚDE, ASSISTÊNCIA E ASSUNTOS DIVERSOS" },
					/* { id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" }, */
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'ja') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO E JUSTIÇA" },
					{ id: 1, name: "COMISSÃO DE FINANÇAS E ORÇAMENTO" },
					{ id: 2, name: "COMISSÃO DE POLITICAS GERAIS" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'angicos') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "Comissão de Constituição, Justiça e Redação" },
					{ id: 1, name: "Comissão de Orçamento, Finanças, Fiscalização e Controle" },
					{ id: 2, name: "Comissão de Obras, Serviços Públicos, Educação, Saúde, Assistência social e outras atividades" },
					{ id: 3, name: "Comissão da Região central do Rio grande do norte" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'goianinha') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "Comissão de Justiça e Redação final" },
					{ id: 1, name: "Comissão de Finanças, Orçamento, Fiscalização e Desenvolvimento econômico" },
					{ id: 2, name: "Comissão de Saúde, Educação, Cultura, Planejamento urbano, Obras públicas e Turismo" },
					{ id: 3, name: "Comissão da Ética parlamentar" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'crv') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "Comissão de Legislação, Justiça e Redação" },
					{ id: 1, name: "Comissão de Finanças, Orçamento e Fiscalização" },
					{ id: 2, name: "Comissão de Parlamento comum" },
					{ id: 3, name: "Comissão de Serviço público" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'odb') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'jucurutu') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'sm') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'lp') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'sr') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'bf') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'sbn') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}else if (this.StoreAll.host == 'pureza') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			} else if (this.StoreAll.host == 'local') {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "AGRICULTURA, OBRAS, TRANSPORTE E URBANISMO" },
					{ id: 3, name: "EDUCAÇÃO, CULTURA, ESPORTE, LAZER, MEIO AMBIENTE E TURISMO" },
					{ id: 4, name: "SAÚDE, ASSISTÊNCIA E ASSUNTOS DIVERSOS" },
				];

				this.comissions = comissions;

			} else {

				let comissions = [
					{ id: 6, name: "PROCURADOR" },
					{ id: 0, name: "COMISSÃO DE CONSTITUIÇÃO, JUSTIÇA E REDAÇÃO" },
					{ id: 1, name: "COMISSÃO DE ORÇAMENTO, FINANÇAS E CONTABILIDADE" },
					{ id: 2, name: "COMISSÃO DE OBRAS E SERVIÇOS PÚBLICOS" },
					{ id: 3, name: "COMISSÃO DE SAÚDE, EDUCAÇÃO, CULTURA, LAZER E TURISMO" },
					{ id: 4, name: "COMISSÃO DE PLANEJAMENTO, USO, OCUPAÇÃO E PARCELAMENTO DO SOLO" },
					{ id: 5, name: "COMISSÃO DE DESENVOLVIMENTO ECONÔMICO E SOCIAL" },
				];

				this.comissions = comissions;

			}
		},

		send_Parecer() {
			this.$refs.Loading.openModal();

			if (this.comissions_choice == 0) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 1);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});

			} else if (this.comissions_choice == 1) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 2);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			} else if (this.comissions_choice == 2) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 3);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			} else if (this.comissions_choice == 3) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 4);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			} else if (this.comissions_choice == 4) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 5);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			} else if (this.comissions_choice == 5) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("comission_id", 6);
				formData.append("parecer_pdf", file);
				formData.append("document_id", this.documento_id);

				AxiosAPI.post("/document-to-comission", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			} else if (this.comissions_choice == 6) {
				let file = document.getElementById("formFile").files[0];

				const formData = new FormData();

				formData.append("document_id", this.documento_id);
				formData.append("parecer_procurador_pdf", file);

				AxiosAPI.post("/parecer-procurador", formData, {
					headers: {
						Authorization: `Bearer ` + this.StoreAll.accessToken,
					},
				})
					.then(() => {
						this.Bus.AxexoSuccess = true;
						this.Doc_parecer_ok_send();
						this.$refs.Loading.closeModal();
						this.$refs.viewDocument.closeModal();
					});
			}

		},

		Doc_parecer_ok_send() {
			this.$toast.add({ severity: 'success', summary: 'Parecer', detail: 'Parecer anexado com sucesso', life: 3000 });
		},

	}
};
</script>
<template>
    <div>
        <Loading ref="Loading" style="z-index: 1002 !important;"></Loading>
    </div>
    <div>
        <ModalBigger ref="viewDocument" style="z-index: 1001;">
            <template #header>
                
                <span v-if="secretario === true" style="color: black"><strong> Anexar parecer a um documento </strong></span>

            
                <span v-else style="color: black"><strong> Anexar parecer ao documento: {{ documento_name }}</strong></span>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>
        
            <div>
                <label>Comissão</label>
                <select id="choices-category" v-model="comissions_choice" class="form-control" name="choices-category"
                    @change="change">
                    <option v-for="option in comissions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>

                <div v-if="secretario === true" class="mt-3 mb-3">
                    <label class="form-label">Selecione o documento principal</label>
                    <select id="choices-category" v-model="document_id_chose" class="form-control" name="choices-category"
                    @change="change">
                    <option v-for="docs in documents_in_secretario" :key="docs.id" :value="docs.id">
                        {{ docs.name }}
                    </option>
                </select>
                </div>

                <div v-else class="mt-3 mb-3">
                    <label for="formFile" class="form-label">Selecione o arquivo</label>
                    <input id="formFile" class="form-control" type="file" accept=".pdf" @change="ValidatInputFile" />
                </div>
            </div>
            </template>

            <template #footer>
            
            <div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                        Fechar
                    </button>
                    <button v-if="secretario === true" type="button" class="btn btn-success" data-dismiss="modal" @click="send_Parecer_append();">
                        anexar parecer!
                    </button>
                    <button v-else type="button" class="btn btn-success" data-dismiss="modal" @click="send_Parecer();">
                        Enviar parecer!
                    </button>
                </div>
            </div>
            </template>
        </ModalBigger>
    </div>
</template>

<style></style>