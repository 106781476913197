<script>
import { AxiosAPI, SocketAPI } from '@/axios';
import Configurator from '@/examples/Configurator.vue';
import Sidenav from '../src/components/Sidenav/index.vue';
import ModalVote from '../src/components/documents/Modals/Modal_Voto.vue';
import VoteVereador from '../src/components/documents/Modals/Modal_Voto_Vereador.vue';
import ModalQuorum from '../src/components/documents/Modals/Modal_Subscriber_Quorum.vue';
import Modalinscrito from '../src/components/documents/Modals/Modalinscrito.vue';
import ModalinscritoAfter from '../src/components/documents/Modals/ModalinscritoAfter.vue';
import Navbar from '../src/components/Navbars/Navbar.vue';
import io from 'socket.io-client';
import { useMainStore } from './stores/index';
import { useSocketStore, useBusStore } from './stores/index';
import { mapState } from 'vuex';
import Cookie from 'js-cookie';

export default {
	name: 'App',
	components: {
		Sidenav,
		ModalVote,
		Modalinscrito,
		ModalinscritoAfter,
		ModalQuorum,
		Navbar,
		VoteVereador,
		Configurator
	},
	setup() {
		const StoreAll = useMainStore();
		const SockertAll = useSocketStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();

		return {
			StoreAll: StoreAll,
			SockertAll: SockertAll,
			Bus: Bus
		};
	},

	data() {
		return {
			fulltela: false,
			users: {},
			session_id: 0,
			session_status: 0,
			quorum_obj: [],
			tribuna_id: 0,
			document_id_nominal: null,
			document_name: '',
			Nominal_vote_result: '',
			vari: [],
			varii: [],
			tribune_session: null,
			quorum_tribune_session: null,
			VotesSocket: [],
			DataVote: null,
			socket: null,
			messages: [],
			socket_ids: [],
		};
	},

	watch: {
		VotesSocket: function (novoValor, valorAntigo) {
			this.StoreAll.allvotes = novoValor;
			this.StoreAll.total_votes = novoValor.length;

			let favorable = 0;
			let against = 0;
			let vista = 0;

			novoValor.forEach(typeVote => {
				if(typeVote.vote_category_id === '1'){
					favorable++;
				} 
				if (typeVote.vote_category_id === '2') {
					against++;
				}
				if (typeVote.vote_category_id === '3') {
					vista++;
				}
			});

			this.StoreAll.favorable = favorable;
			this.StoreAll.against = against;
			this.StoreAll.vista = vista;

			novoValor.forEach(myvote => {
				if (myvote.user_id == this.StoreAll.user.id) {
					this.StoreAll.userVote = myvote;
				}
			});
		},

		'StoreAll.VoteConf': function (newValue, oldPath) {
			if (newValue === 1) {
				this.StoreAll.VoteConf = null;
				this.UserVotedModal();
				this.showSuccess_voteF();
			} else if (newValue === 2) {
				this.StoreAll.VoteConf = null;
				this.UserVotedModal();
				this.showSuccess_voteC();
			} else if (newValue === 3) {
				this.StoreAll.VoteConf = null;
				this.UserVotedModal();
				this.showSuccess_voteA();
			}
		},

		'Bus.tribuneEmitModalOne': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.tribuneEmitModalOne = null;
				this.$toast.add({ severity: 'success', summary: 'Inscrição na tribuna', detail: 'Você foi inscrito na tribuna com sucesso', group: 'tl', life: 8000 });
			}
		},

		'Bus.tribuneEmitModalTwo': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.tribuneEmitModalTwo = null;
				this.$toast.add({ severity: 'success', summary: 'Inscrição na tribuna', detail: 'Você optou por não se inscrever', group: 'tl', life: 8000 });
			}
		},

		'Bus.tribuneEmitModalThree': function (newValue, oldPath) {
			if (newValue === true) {
				this.Bus.tribuneEmitModalThree = null;
				this.$toast.add({ severity: 'success', summary: 'Inscrição na tribuna', detail: 'Você foi já está inscrito na tribuna', group: 'tl', life: 8000 });
			}
		},

		'Bus.ModalVoteOn': function (newValue, oldPath) {
			console.log(newValue);
		},
	},
	computed: {
		...mapState([
			'layout',
			'showSidenav',
			'showNavbar',
			'showFooter',
			'showConfig',
			'hideConfigButton',
		]),
	},

	mounted() {
		const url = window.location.href;
		const startIndex = url.indexOf('https://') + 8;
		const pathIndex = url.indexOf('/', startIndex);
		const result = url.substring(startIndex, pathIndex);
		let websocket_host = '';
		if (result == 'dev.smartlegis.net.br') {
			this.StoreAll.host = 'dev';
			this.StoreAll.voteSocket = 'dev';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE DEVS - RN';
			this.StoreAll.Large_host = 13;
			this.StoreAll.doc_link = 'https://devapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-dev.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocketdev.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Devapp';
		} else if (result == 'teste.smartlegis.net.br') {
			this.StoreAll.host = 'teste';
			this.StoreAll.voteSocket = 'teste';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE TESTE - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://sandbox.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Devapp';
		} else if (result == 'jc.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'jc';
			this.StoreAll.host = 'jc';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE JOÃO CÂMARA - RN';
			this.StoreAll.Large_host = 13;
			this.StoreAll.doc_link = 'https://jcapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Jcapp';
		} else if (result == 'bd.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'bd';
			this.StoreAll.host = 'bodo';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE BODÓ - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://bodoapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Bodoapp';
		} else if (result == 'pa.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'pa';
			this.StoreAll.host = 'pedroavelino';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE PEDRO AVELINO - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://paapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Paapp';
		} else if (result == 'pp.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'pp';
			this.StoreAll.host = 'pedrapreta';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE PEDRA PRETA - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://ppapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Ppapp';
		} else if (result == 'cg.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'cg';
			this.StoreAll.host = 'campogrande';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE CAMPO GRANDE - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://cgapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Cgapp';
		} else if (result == 'lajes.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'lajes';
			this.StoreAll.host = 'lajes';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE LAJES - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://lajesapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Lajesapp';
		} else if (result == 'parazinho.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'parazinho';
			this.StoreAll.host = 'parazinho';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE PARAZINHO - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://parazinhoapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Parazinhoapp';
		} else if (result == 'jd.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'jd';
			this.StoreAll.host = 'jandaira';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE JANDAÍRA - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://jandairaapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Jandairaapp';
		} else if (result == 'tp.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'tp';
			this.StoreAll.host = 'triunfo';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE TRIUNFO POTIGUAR - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://tpapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Tpapp';
		} else if (result == 'upanema.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'upanema';
			this.StoreAll.host = 'upanema';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE UPANEMA - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://upnapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Upanemaapp';
		} else if (result == 'ja.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'ja';
			this.StoreAll.host = 'ja';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE JARDIM DE ANGICOS - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://jaapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Jaapp';
		} else if (result == 'angicos.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'angicos';
			this.StoreAll.host = 'angicos';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE ANGICOS - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://angicosapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Agicosapp';
		} else if (result == 'crv.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'crv';
			this.StoreAll.host = 'crv';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE CRV - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://crvapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Crvapp';
		} else if (result == 'goianinha.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'goianinha';
			this.StoreAll.host = 'goianinha';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE GOIANINHA - RN';
			this.StoreAll.Large_host = 11;
			this.StoreAll.doc_link = 'https://goianinhaapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Goianinhaapp';
		} else if (result == 'jucurutu.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'jucurutu';
			this.StoreAll.host = 'jucurutu';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE JUCURUTU - RN';
			this.StoreAll.Large_host = 11;
			this.StoreAll.doc_link = 'https://jucurutuapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Jucurutuapp';
		}else if (result == 'odb.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'odb';
			this.StoreAll.host = 'odb';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE OLHO-D\'ÁGUA DO BORGES - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://odbapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Odbapp';
		}else if (result == 'sm.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'sm';
			this.StoreAll.host = 'sm';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE SERRA DO MEL - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://smapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Smapp';
		}else if (result == 'lp.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'lp';
			this.StoreAll.host = 'lp';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE LAGOA DE PEDRAS - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://lpapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Lpapp';
		}else if (result == 'sr.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'sr';
			this.StoreAll.host = 'sr';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE SÃO RAFAEL - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://srapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Srapp';
		}else if (result == 'bf.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'bf';
			this.StoreAll.host = 'bf';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE BAIA FORMOSA - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://bfapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Bfapp';
		}else if (result == 'sbn.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'sbn';
			this.StoreAll.host = 'sbn';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE SÃO BENTO DO NORTE- RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://sbnapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Sbnapp';
		}else if (result == 'pureza.smartlegis.net.br') {
			this.StoreAll.voteSocket = 'pureza';
			this.StoreAll.host = 'pureza';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE PUREZA - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://purezaapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-production.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocket.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Purezaapp';
		}else if (result == 'localhost:8080') {
			this.StoreAll.voteSocket = 'dev';
			this.StoreAll.host = 'local';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE LOCAL - RN';
			this.StoreAll.Large_host = 9;
			this.StoreAll.doc_link = 'https://devapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-dev.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocketdev.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Devapp';
		} else {
			this.StoreAll.voteSocket = 'dev';
			this.StoreAll.host = 'local';
			this.StoreAll.name_camara = 'CÂMARA MUNICIPAL DE LOCAL - RN';
			this.StoreAll.Large_host = 13;
			this.StoreAll.doc_link = 'https://devapp.smartlegis.net.br/';
			this.StoreAll.Dochost = 'https://smartlegis-dev.s3.amazonaws.com';
			this.StoreAll.SocketLink = 'https://smartsocketdev.smartlegis.net.br';
			this.StoreAll.websocket_host = 'Devapp';
		}

		let OnSystem = setInterval(() => {
			if (this.$route.path === '/externo') {clearInterval(OnSystem);} else {
				if (this.$route.name === null || this.$route.name === undefined || this.$route.name !== 'Login') {
					clearInterval(OnSystem);
					this.Document_Socket();
					this.telafull();
					this.emitter.on('FullScren', (FullScren) => { this.fulltela = FullScren; });
					this.emitter.on('FullScrennot', (FullScrennot) => { this.fulltela = FullScrennot; });


					this.emitter.on('subscribe_user', (subscribe_user) => {
						this.Users_in_quorum_tribune_Socket();
						this.Users_in_quorum_Socket();
					});

					window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
						if (e.socket_id === 1) {
							if (this.socket_ids.includes(e.event_id)) {
								return;
							} else {
								this.Session_Socket();
								this.getQuorumValue();
								this.socket_ids.push(e.event_id);
							}
						}

						if (e.socket_id === 2) {
							let idCookie = Cookie.get('session_end');
							if (idCookie == e.event_id) {
								return;
							} else {
								Cookie.set('session_end', e.event_id, { expires: 1 });
								if (this.StoreAll.user.user_category_id !== 6) {
									window.location.reload();
								}
							}
						}

						if (e.socket_id === 4) {
							if (this.socket_ids.includes(e.event_id)) {
								return;
							} else {
								this.Users_in_quorum();
								this.socket_ids.push(e.event_id);
							}
						}

						if (e.socket_id === 5) {
							if (this.socket_ids.includes(e.event_id)) {
								return;
							} else {
								this.Users_in_quorum();
								this.socket_ids.push(e.event_id);
							}
						}

						if (e.socket_id === 8) {
							if (this.SockertAll.socket_id_nominalvote.includes(e.event_id)) {
								return;
							} else {
								console.log(e);
								this.vari = e.data.votes;
								this.Bus.nominalVote = true;
								this.SockertAll.socket_id_nominalvote.push(e.event_id);
							}
						}

						if (e.socket_id === 9) {
							if (this.SockertAll.socket_id_nominalvote.includes(e.event_id)) {
								return;
							} else {
								console.log(e);
								this.vari = e.data.votes;
								this.NominalVote_resp();
								this.Bus.nominalVote = true;
								this.SockertAll.socket_id_nominalvote.push(e.event_id);
							}
						}

						if (e.socket_id === 10) {
							if (this.socket_ids.includes(e.event_id)) {
								return;
							} else {
								this.Quorum_data_tribuna_Socket();
								this.socket_ids.push(e.event_id);
							}
						}

						if (e.socket_id === 12) {
							if (this.socket_ids.includes(e.event_id)) {
								return;
							} else {
								this.Quorum_data_tribuna_Socket();
								this.socket_ids.push(e.event_id);
							}
						}

					});

					this.Category_user_socket();
					this.Session_Socket();
					this.Quorum_data_tribuna_Socket();
					this.getTimer();
				}
			}}, 500);
	},

	  created() {
		let OnSystem = setInterval(() => {
			if (this.$route.path === '/externo') {clearInterval(OnSystem);} else {
				if (this.$route.name === null || this.$route.name === undefined || this.$route.name !== 'Login') {
					clearInterval(OnSystem);

					this.StoreAll.socket = io(this.StoreAll.SocketLink,
						{
							transports: ['websocket'],
							query: {
								'x-tenant': this.StoreAll.voteSocket
							}
						}
					);

					this.StoreAll.socket.on('6', (e) => {
						if (this.Bus.ModalVoteOn === false) {
							if (this.StoreAll.user.user_category_id !== 2 && this.StoreAll.user.user_category_id !== 3) {
								this.Document_Socket();
								this.Bus.ModalVoteOn = true;
							}				
							this.Document_Socket();
						}
					});

					this.StoreAll.socket.on('9', (e) => {
						this.VotesSocket = e;
					});

					this.StoreAll.socket.on('8', (e) => {
						this.DocumentSocketEnd(e);
						this.Bus.ModalVoteOn = false;
					});
				

	  }}}, 500);

	},

	methods: {

		NominalVote_resp() {
			console.log(this.vari);
			const ent = this.vari;

			for (let i = 0; i < ent.length; i++) {
				this.document_id_nominal = ent[i].document_id;
				if (ent[i].vote_category_id == 1) {
					this.Nominal_vote_result = 'aprovado';
				} else if (ent[i].vote_category_id == 2) {
					this.Nominal_vote_result = 'reprovado';
				} else {
					this.Nominal_vote_result = 'aguarde...';
				}
			}
			setTimeout(() => { this.getdocument_nominal(); }, 500);

		},

		async getdocument_nominal() {
			await AxiosAPI
				.get('/documents?id=' + this.document_id_nominal, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					this.document_name = response.data.data[0].name;
					this.showCenter();
				});
		},

		showCenter() {
			this.$toast.add({ severity: 'info', summary: 'Votação nominal', detail: 'O documento ' + this.document_name + ' foi votado nominalmente e está ' + this.Nominal_vote_result + '', group: 'tl', life: 8000 });
		},

		async Category_user_socket() {
			await this.StoreAll.User();
		},

		async Session_Socket() {
			await this.StoreAll.Get_Data();
		},

		async Document_Socket() {
			try {
				const vote = await this.StoreAll.GetDocument();
				this.DataVote = vote;
				vote.forEach(Document => {
					Document.document_sessions.forEach(SessionData => {
						SessionData.quorum.users.forEach(ValidateUser => {
							if (ValidateUser.id === this.StoreAll.user.id) {
								if (this.StoreAll.user.user_category_id === 2) {
									this.$refs.VoteVereador.startvote(1);
								} else {
									let data = {0: this.StoreAll.document.id,
										1: true,
										2: 1,
									};
									this.$refs.VotePresidente.startvote(data
									);
								}
							}
          
						});

					});

				});
				if (!this.StoreAll.voted) {
					this.getVotes();
				}
			} catch (erro) {
				console.error('Erro ao despachar ação:', erro);
			}
		},

		UserVotedModal () {
			this.DataVote.forEach(Document => {
				Document.document_sessions.forEach(SessionData => {
					SessionData.quorum.users.forEach(ValidateUser => {
						if (ValidateUser.id === this.StoreAll.user.id) {
							if (this.StoreAll.user.user_category_id === 2) {
								this.$refs.VoteVereador.startvote(1);
							} else {
								let data = {0: this.StoreAll.document.id,
									1: true,
									2: 1,
								};
								this.$refs.VotePresidente.startvote(data);
							}
						}         
					});
				});
			});
		},

		async DocumentSocketEnd (value) {
			try {
				let favorable = 0;
				let against = 0;
				let vista = 0;

				value.forEach(typeVote => {
					if(typeVote.vote_category_id === '1'){
						favorable++;
					} 
					if (typeVote.vote_category_id === '2') {
						against++;
					}
					if (typeVote.vote_category_id === '3') {
						vista++;
					}
				});

				if (favorable >= against && favorable >= vista) {
					this.StoreAll.voteResult = 1;
				} else if (against >= favorable && against >= vista) {
					this.StoreAll.voteResult = 2;
				} else {
					this.StoreAll.voteResult = 3;
				}

				this.StoreAll.favorable = favorable;
				this.StoreAll.against = against;
				this.StoreAll.vista = vista;

				let id = value[0].document_id;
				const DocEnd = await this.StoreAll.GetDocumentEndVote(id);

				DocEnd.forEach(Document => {
					Document.document_sessions.forEach(SessionData => {
						SessionData.quorum.users.forEach(ValidateUser => {
							if (ValidateUser.id === this.StoreAll.user.id) {
								if (this.StoreAll.user.user_category_id === 2) {
									this.$refs.VoteVereador.startvote(2);
								} else {
									let data = {0: this.StoreAll.document.id,
										1: true,
										2: 2,
									};
									this.$refs.VotePresidente.startvote(data);
								}
							}        
						});

					});

				});

			} catch {
				console.error('Erro ao despachar ação:', erro);
			}
		},

		stop_socket(event) {
			AxiosAPI.post('/socket/' + event + '/success', {}, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}
			)
				.then((response) => {
					console.log(response);
				});
		},

		async getTimer() {
			await this.StoreAll.getTimers();
		},

		getQuorumValue () {
			let OnSystem = setInterval(() => {
				if (this.StoreAll.quorum.length >= 1) {
					clearInterval(OnSystem);
					this.Users_in_quorum_Socket();
				}}, 300);
		},

		async Users_in_quorum_Socket() {
			await AxiosAPI
				.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				})
				.then((response) => {
					let quorum_users_array = response.data.data;
					let size = quorum_users_array.length;
					this.StoreAll.quorum_users = response.data.data;
					this.StoreAll.quorum_size = size;
					this.StoreAll.in_quorum = false;
					for (let i = 0; i < size; i++) {
						if (
							this.StoreAll.user.id == quorum_users_array[i].user_id
						) {
							this.StoreAll.in_quorum = true;
						}
					}
					this.Check_Status();
					this.Modal_On_Socket(); // por fim chamamos a abertura do modal responsavel pela votação.
				});
		},

		async Users_in_quorum() {
			try {
				await AxiosAPI
					.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					})
					.then((response) => {
						let quorum_users_array = response.data.data;
						this.StoreAll.quorum_users = response.data.data;
						let size = quorum_users_array.length;
						this.StoreAll.quorum_size = size;
						this.StoreAll.in_quorum = false;
						for (let i = 0; i < size; i++) {
							if (this.StoreAll.user.id === quorum_users_array[i].user_id) {
								this.StoreAll.in_quorum = true;
							}
						}
					});
			} catch (error) {}
		/* } */
		},


		Modal_On_Socket() {
			if (this.StoreAll.in_quorum == true) {
				if (this.StoreAll.document === null) {
					return;
				} else {
					if (this.StoreAll.user.user_category_id === 2) {
						this.$refs.VoteVereador.startvote(1);
					} else {
						let data = {0: this.StoreAll.document.id,
							1: true,
							2: 1,
						};
						this.$refs.VotePresidente.startvote(data);
					}

					this.getVotes();
				}
			} else {
				return;
			}
		},

		/* TRIBUNA SOCKET */
		async Get_Session_Tribune() {
			await this.StoreAll.Get_Data();
			/* await AxiosAPI.get('/sessions', {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => { */
			/* let size = resp.data.data.length;
				let session = resp.data.data[size - 1]; */

			this.tribune_session = this.StoreAll.session;
			this.Quorum_data_tribune();
			/* verificar se isso tem algum impacto relevante */
			/* }); */
		},

		async Quorum_data_tribune() {
			await AxiosAPI.get('/quorum?session_id=' + this.tribune_session.id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			})
				.then((response) => {
					let quorum = response.data.data;

					this.quorum_tribune_session = quorum;

					this.Users_in_quorum_tribune_Socket();
				});
		},
		Quorum_data_tribuna_Socket() {
			this.Get_Session_Tribune();
		},

		async Users_in_quorum_tribune_Socket() {
			if (this.quorum_tribune_session == null) {
				this.t = this.quorum_tribune_session;
				return;
			} else if (this.quorum_tribune_session.length <= 0) {
				this.t = 0;
				return;
			} else {
				await AxiosAPI
					.get('/quorum-users?quorum_id=' + this.quorum_tribune_session[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					})
					.then((response) => {
						let quorum_users_array = response.data.data;
						this.StoreAll.quorum_users = response.data.data;
						let size = quorum_users_array.length;
						this.StoreAll.quorum_size = size;
						this.StoreAll.in_quorum = false;
						for (let i = 0; i < size; i++) {
							if (this.StoreAll.user.id == quorum_users_array[i].user_id) {
								this.StoreAll.in_quorum = true;
							}
						}
						this.Tribuna_Websocket();
						this.Check_Status();
					});
			}
		},


		async Check_Status() {
			if (this.StoreAll.user.status_user === 1) {
				if (!this.StoreAll.in_quorum) {
					this.$refs.ViewModalQuorum.openModal();
				}
			}
		},

		async Tribuna_Websocket() {
			await AxiosAPI.get('/tribune?quorum_id=' + this.quorum_tribune_session[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((resp) => {
				if (resp.data.data.length == undefined) {
					return;
				} else if (resp.data.data.length <= 0) {
					return;
				} else if (resp.data.data[0].status_tribune == undefined) {
					return;
				} else {
					this.StoreAll.status_tribune = resp.data.data[0].status_tribune;
					this.StoreAll.tribuna_id = resp.data.data[0].id;

					this.Tribuna_User_websocket();
				}
			});
		},

		async Tribuna_User_websocket() {
			if (this.StoreAll.user.user_category_id != 1) {
				if (this.StoreAll.in_quorum == true) {
					await AxiosAPI.get('/tribune-users?tribune_id=' + this.StoreAll.tribuna_id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						if (response.data.data.length <= 0) {
							this.Modal_on_Tribuna_websocket();
						} else {
							for (let i = 0; i < response.data.data.length; i++) {
								if (response.data.data[i].user.id == this.StoreAll.user.id) {
									return;
								} else {
									this.Modal_on_Tribuna_websocket(response.data.data);
								}
							}
						}
					});
				}
			} else {
				return;
			}
		},

		Modal_on_Tribuna_websocket(value) {
			let userIn = [];
			if (value !== undefined) {
				value.forEach(element => {
					if(element.user.id === this.StoreAll.user.id) {
						userIn.push(element.user.id);
					}
				});
			}

			if (this.StoreAll.status_tribune == 1) {
				this.$refs.viewDocument3.openModal2();
			} else if (this.StoreAll.status_tribune == 2 && userIn.length <=0 && this.StoreAll.host == 'campogrande') {
				if (this.StoreAll.user.user_category_id === 3) {
					this.$refs.viewDocument4.openModal2();
				}
			}   
			else {
				return;
			}
		},

		showSuccess_voteF() {
			this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto a favor foi computado', life: 3000 });
		},
		showSuccess_voteC() {
			this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto contra foi computado', life: 3000 });
		},
		showSuccess_voteA() {
			this.$toast.add({ severity: 'success', summary: 'Voto computado', detail: 'Seu voto como abstenção foi computado', life: 3000 });
		},

		async getVotes() {
			if (!this.StoreAll.document) {
				return;
			} else {
				let document_id = this.StoreAll.document.id;
				await SocketAPI
					.get('/votes?document_id=' + document_id + '&session_id=' + this.StoreAll.session.id,
						{
							headers: {
								'x-tenant': this.StoreAll.voteSocket,
								Authorization: 'Bearer ' + this.StoreAll.socketToken,
							},
						}
					)
					.then((response) => {
						this.StoreAll.votachion = response.data.data;
						let arraydata = response.data.data;
						let data_array = [];
						let favorable = 0,
							against = 0,
							vista = 0;
						let user_voted = false;
						this.StoreAll.total_votes = arraydata.length;
						for (let i = 0; i < arraydata.length; i++) {
							if (arraydata[i].user_id == this.StoreAll.user.id) {
								user_voted = true;
							}
							let obj = {};
							obj.vote_category_id = arraydata[i].vote_category_id;
							obj.user_id = arraydata[i].user_id;
							data_array.push(obj);
							switch (obj.vote_category_id) {
							case 1:
								favorable++;
								break;
							case 2:
								against++;
								break;
							case 3:
								vista++;
								break;
							default:
								console.log(
									'ERRO! TIPO DE VOTO NÃO RECONHECIDO' +
                    favorable +
                    against +
                    vista
								);
							}
						}
						this.StoreAll.voted = user_voted;
						this.StoreAll.favorable = favorable;
						this.StoreAll.against = against;
						this.StoreAll.vista = vista;
						this.StoreAll.allvotes = arraydata;

						arraydata.forEach(myvote => {
							if (myvote.user_id === this.StoreAll.user.id) {
								this.StoreAll.userVote = myvote;
							}
						});

					});
			}
		},

		getVotesSocket(value) { 
			this.StoreAll.allvotes = arraydata;
		},


		telafull() {
			setTimeout(() => {
				this.telafull();
				this.telafull2();
			}, 2000);
			if (this.$route.name != 'Tela de sessão') {
				if (localStorage.getItem('full') == 'ok') {
					localStorage.removeItem('full');
					location.reload();
				}
			}
		},

		telafull2() {
			if (this.$route.name != 'Tela de sessão up') {
				if (localStorage.getItem('full_2') == 'ok') {
					localStorage.removeItem('full_2');
					location.reload();
				}
			}
		},

		//Finaliza as funções da tribuna ---  
	},
};
</script>
<template>
  <div>
    <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>

    <div>
      <ModalVote ref="VotePresidente"></ModalVote>
      <VoteVereador ref="VoteVereador"></VoteVereador>
    </div>

    <div>
      <Modalinscrito ref="viewDocument3"></Modalinscrito>
      <ModalinscritoAfter ref="viewDocument4"></ModalinscritoAfter>
    </div>
    <div>
      <ModalQuorum ref="ViewModalQuorum"></ModalQuorum>
    </div>

    <sidenav v-if="showSidenav && fulltela == false && this.$route.name != '404' && this.$route.path !== '/externo'" />

    <main class="main-content position-relative max-height-vh-100 h-100">
      <navbar v-if="showNavbar && fulltela == false && this.$route.name != '404' && this.$route.path !== '/externo'" />

      <router-view />
      <div
        v-if="this.$route.name == null || this.$route.name == undefined || this.$route.name == 'Login' || this.$route.name == 'Tela de sessão' || this.$route.name == 'Tela de sessão up' || this.$route.name == '404'">
      </div>
      <div v-else>
        <configurator />
      </div>

    </main>
    <Toast />
    <Toast position="center" group="tl" />
  </div>
</template>
